<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
/**
 * Advanced table component
 */
export default {
  page: {
    title: "Riders",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Users",
      items: [
        {
          text: "Dashboard",
          href: "/"
        },
        {
          text: "Users",
          active: true
        }
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "createdAt",
      sortDesc: true,
      fields: [
        { key: "firstName", label: 'First Name', sortable: true },
        { key: "lastName", label: 'Last Name', sortable: true },
        { key: "email", label: 'Email', sortable: true },
        { key: "phoneNumber", label: 'Phone Number', sortable: true },
        // { key: "state",label:'State', sortable: true },
        // { key: "device_type",label:'Device Type', sortable: true },
        // { key: "action" }
      ]
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    //  riders(){
    //   return this.$store.getters['riders/ridersGetter'];
    // },
    users() {
      return this.$store.getters['users/getUsers']
    },
    rows() {
      return this.users.length;
    }

  },

  mounted() {
    // Set the initial number of items
    this.$store.dispatch('users/getUsers')
    this.totalRows = this.items.length;

  },


  methods: {
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title font-size-20"><strong> All Users</strong></h4>
            <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show&nbsp;
                    <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;entries
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input v-model="filter" type="search" placeholder="Search..."
                      class="form-control form-control-sm ml-2"></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table :items="users" :fields="fields" responsive="sm" :per-page="perPage" :current-page="currentPage"
                :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :filter="filter" :filter-included-fields="filterOn"
                @filtered="onFiltered">

                <!-- <template v-slot:cell(action)="{ item }">
                  <a class="mr-3 text-primary" href="javascript:void(0);" v-b-tooltip.hover data-toggle="tooltip"
                    title="Details" @click="$router.push({ path: `/riders/${item._id}` })">
                    <i class="ri-folder-shared-fill font-size-24"></i>
                  </a>
                  <a class="mr-3 text-primary" href="javascript:void(0);" v-b-tooltip.hover data-toggle="tooltip"
                    title="Rides" @click="$router.push({ path: `/riders/rides/${item._id}` })">
                    <i class="ri-taxi-wifi-fill font-size-24"></i>
                  </a>
                </template> -->
              </b-table>
            </div>

            <div class="row " v-show="users.length === 0">
              <div class="col text-center">
                <b-spinner class="m-2" variant="primary" role="status"></b-spinner>
              </div>
            </div>

            <div class="row">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-right">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                  </ul>
                </div>
              </div>
            </div>


          </div>
        </div>
      </div>
    </div>



  </Layout>
</template>